import React from "react";
import { logo } from "../assets";

function Angarachane() {
  return (
    <>
      <div className="min-h-screen  p-3  md:px-20 lg:px-32 ">
        <img
          src={logo}
          alt=""
          className="absolute -z-10 top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] opacity-5"
        />
        <div className="">
          <h1 className="text-3xl my-3  font-semibold text-green">
            ಸಂಘದ ರಚನೆ ಹಾಗೂ ಕಾರ್ಯವೈಖರಿ
          </h1>
          {/* <p className="text-2xl font-semibold">
            ಗೂಗಲ್ ಮೀಟ್ ಮುಖೇನ ಸಂಘದ ಸದಸ್ಯರಿಗೆ ಕಲಿಕೆ ಕಾರ್ಯಾಗಾರಗಳು.
          </p> */}
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>
                ಸಂಘಕ್ಕೆ ಸದಸ್ಯತ್ವವನ್ನು ನೀಡಲಾಗುತ್ತದೆ.ಸದಸ್ಯರನ್ನು ಅವರು
                ಕಾರ್ಯನಿರ್ವಹಿಸುವ ಜಿಲ್ಲೆಯ ವಾಟ್ಸಾಪ್ ಗುಂಪಿಗೆ ಸೇರಿಸಲಾಗುತ್ತದೆ.
              </li>
              <li>
                ಸದಸ್ಯರಿಗೆ ಸದಸ್ಯತ್ವದ ಗುರುತಿನ ಚೀಟಿಗಳನ್ನು ನೀಡಲಾಗುತ್ತದೆ.ಹಾಗೂ ಮತದಾನದ
                ಹಕ್ಕನ್ನು ಹೊಂದುತ್ತಾರೆ.
              </li>
              <li>
                ತಾಲ್ಲೂಕು ಮಟ್ಟದ ಪದಾಧಿಕಾರಿಗಳನ್ನು ಸದಸ್ಯರು ತಮ್ಮತಮ್ಮಲಿಯೇ
                ಮಾಡಿಕೊಳ್ಳುವುದು. ಅಗತ್ಯವಿರುವ ಸ್ಥಳಗಳಲ್ಲಿ ಚುನಾವಣೆ ಮೂಲಕ
                ಆರಿಸಲಾಗುತ್ತದೆ.
              </li>
              <li>
                ಜಿಲ್ಲಾಮಟ್ಟದಲ್ಲಿ ಚುನಾವಣೆಗಳನ್ನು ನಡೆಸುವ ಮೂಲಕ ಜಿಲ್ಲಾಧ್ಯಕ್ಷರನ್ನು
                ನೇಮಿಸಲಾಗುತ್ತದೆ.ಜಿಲ್ಲೆಯ ಅಧ್ಯಕ್ಷರು ಕ್ಷೇಮಾಭಿವೃಧ್ದಿ ಸಂಘದ ಬೋರ್ಡ್ ಆಫ್
                ಮೆಂಬರ್ ಆಗುತ್ತಾರೆ.
              </li>
              <li>
                ಬೋರ್ಡ್ ಆಫ್ ಮೆಂಬರ್ ಗಳು ಸಭೆ ಸೇರಿ ರಾಜ್ಯದ ಪದಾಧಿಕಾರಿಗಳನ್ನು ಚುನಾವಣೆಯ
                ಮೂಲಕ ಆರಿಸತಕ್ಕದ್ದು.
              </li>
              <li>
                ಯೋಜನಾ ಸಮಿತಿ, ವಿತ್ತೀಯ ಸಮಿತಿ, ಕಾನೂನು ಸಮಿತಿ, ಮಾಧ್ಯಮ ಸಮಿತಿ,
                ಕಾರ್ಯಕಾರಿ ಸಮಿತಿ,ಜಾರಿ ಸಮಿತಿಗಳಿರುತ್ತವೆ.
              </li>
              <li>
                ಬೋರ್ಡ್ ಆಫ್ ಮೆಂಬರ್ಸ್ ಗಳು ತಮ್ಮ ಕೌಶಲ್ಯಕ್ಕನುಗುಣವಾಗಿ ಮೇಲ್ಕಂಡ ಸಮಿತಿಗಳ
                ನೇತೃತ್ವ ವಹಿಸಿಕೊಳ್ಳತಕ್ಕದ್ದು ಅಥವಾ ಕೌಶಲ್ಯ ಇರುವ ವ್ಯಕ್ತಿಗಳನ್ನು
                ನೇತೃತ್ವ ವಹಿಸಿಕೊಳ್ಳಲು ನಿರ್ದೇಶಿಸಬಹುದು.
              </li>
              <li>ಸಂಘದ ಕ್ಷೇಮಾಭಿವೃಧ್ದಿಗೆ ಯೋಜನೆಯಂತೆ ಕಾರ್ಯಪ್ರವೃತ್ತರಾಗುವುದು.</li>
            </ul>
          </p>
        </div>
        <div className="">
          <h1 className="text-3xl my-3  font-semibold text-green">
            ಸಮಿತಿಗಳು ನಿರ್ವಹಿಸಬೇಕಾದ ಕೆಲಸಗಳು
          </h1>
          <p className="text-2xl font-semibold">ಯೋಜನಾ ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>
                ವಾರ್ಷಿಕವಾಗಿ ಸಂಘವು ಹಮ್ಮಿಕೊಳ್ಳುವ ಕಾರ್ಯಕ್ರಮಗಳ ಪಟ್ಟಿಯನ್ನು ಸಂಘದ ಬಜೆಟ್
                ಗೆ ಅನುಗುಣವಾಗಿ ತಯಾರಿಸುವುದು.
              </li>
              <li>ಕಾರ್ಯಕ್ರಮಗಳ ವಿವರವಾದ ಯೋಜನೆಯನ್ನು ರೂಪಿಸುವುದು.</li>
              <li>
                ಸಂಘದ ವಾರ್ಷಿಕ " ಕ್ಯಾಲೆಂಡರ್ ಆಫ್ ಈವೆಂಟ್ಸ್" ಅನ್ನು ತಯಾರಿಸುವುದು.
              </li>
              <li>
                ಸಂಘದ ಸದಸ್ಯರ ಅವಶ್ಯಕತೆಗಳು ಮತ್ತು ಬೇಡಿಕೆಗಳಿಗನುಗುಣವಾಗಿ
                ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಯೋಜಿಸುವುದು.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          {/* <h1 className="text-3xl my-3  font-semibold text-green">
ವಿತ್ತೀಯ ಸಮಿತಿ:-

          </h1> */}
          <p className="text-2xl font-semibold">ವಿತ್ತೀಯ ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>ಸಂಘದ ರಿಜಿಸ್ಟರ್ ಅನ್ನು ನಿರ್ವಹಿಸುವುದು.</li>
              <li>ಸಂಘದ ಖರ್ಚು ವೆಚ್ಚಗಳನ್ನು, ಲೆಕ್ಕಪತ್ರಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು.</li>
              <li>
                ಕಾಲಕಾಲಕ್ಕೆ ಬಜೆಟ್ ಅನ್ನೂ ಲೆಕ್ಕಪತ್ರಗಳನ್ನೂ ಪಾರದರ್ಶಕವಾಗಿ
                ಪ್ರದರ್ಶಿಸುವುದು.
              </li>
              <li>
                ಯೋಜನಾ ಸಮಿತಿಯ ಕಾರ್ಯಕ್ರಮಗಳ ಆಧಾರದ ಮೇಲೆ ಖರ್ಚುವೆಚ್ಚಗಳನ್ನು ಲೆಕ್ಕ
                ಹಾಕುವುದು.
              </li>
              <li>ದುಂದುವೆಚ್ಚ, ಸೋರಿಕೆ,ಪೋಲಾಗದಂತೆ ನಿಗಾ ಇಡುವುದು.</li>
            </ul>
          </p>
        </div>
        <div className="">
          <p className="text-2xl font-semibold">ಕಾನೂನು ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>
                ಸಂಘದ ಸದಸ್ಯರಿಗೆ ಸಂಘದ ನಿರ್ವಹಣೆ & ದುರ್ಬಳಕೆಯಾಗದಂತೆ ಎಚ್ಚರ ವಹಿಸಲು
                ಕಾನೂನು ಮಾಹಿತಿ ನೀಡುವುದು.
              </li>
              <li>ಸಂಘದ ಬೈಲಾ & ರಿಸ್ಯೂಲೂಷನ್ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸುವುದು.</li>
              <li>ಸಂಘದ ವಿರುಧ್ದದ ಚಟುವಟಿಕೆಗಳಿಗೆ ಕಾನೂನು ಕ್ರಮ ಜರುಗಿಸುವುದು</li>
              <li>
                ಸಭೆಗಳಿಗೆ ಹಾಜರಾಗಲು ನೋಟೀಸ್ ನೀಡವುದು. ಶಿಸ್ತುಪಾಲನೆಗೆ ಶ್ರಮಿಸುವುದು.
              </li>
              <li>
                ಸಂಘದ ಸದಸ್ಯರ ಏಳಿಗೆಗೆ ಸಹಕಾರಿಯಾಗಬಲ್ಲ ಸಾಕ್ಷ್ಯಗಳ ಸಂಗ್ರಹಣೆ ಮಾಡುವುದು &
                ಕ್ರೋಢೀಕರಿಸುವುದು.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          <p className="text-2xl font-semibold">ಮಾಧ್ಯಮ ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>ಸಂಘದ ಕಾರ್ಯಕ್ರಮಗಳ ಬಗ್ಗೆ ಪ್ರಚುರ ಪಡಿಸುವುದು.</li>
              <li>ಸಂಘದ ಸಭಾ ನಡಾವಳಿಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು & ಪ್ರಚುರಪಡಿಸುವುದು.</li>
              <li>ಪತ್ರಿಕಾ ಮಾಧ್ಯಮ & ಟಿ.ವಿ. ಮಾಧ್ಯಮಗಳ ಸಮನ್ವಯ ಸಾಧಿಸುವುದು.</li>
              <li>
                ಸಭೆಗಳಿಗೆ ಹಾಜರಾಗಲು ನೋಟೀಸ್ ನೀಡವುದು. ಶಿಸ್ತುಪಾಲನೆಗೆ ಶ್ರಮಿಸುವುದು.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          <p className="text-2xl font-semibold">ಕಾರ್ಯಕಾರಿ ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>ಕಾರ್ಯಕ್ರಮಗಳ ಅನುಷ್ಟಾನಕ್ಕೆ ನೆರವಾಗುವುದು.</li>
              <li>ಯೋಜನೆಗಳ ಜಾರಿಗೆ ನೆರವಾಗುವುದು.</li>
              <li>
                ಸದಸ್ಯರೊಡನೆ ಸಂಪರ್ಕದಲ್ಲಿದ್ದುಕೊಂಡು ಕುಂದುಕೊರತೆಗಳ ಬಗ್ಗೆ
                ಸಮಾಲೋಚಿಸುವುದು.
              </li>
              <li>
                ಸಂಘಟನೆಯನ್ನು ಬಲಿಷ್ಟಗೊಳಿಸಲು & ಸದಸ್ಯತ್ವ ನೀಡಲು ಕಾರ್ಯಕ್ರಮಗಳನ್ನು
                ಹಮ್ಮಿಕೊಳ್ಳುವುದು.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          <p className="text-2xl font-semibold">ಜಾರಿ ಸಮಿತಿ:-</p>
          <p className="lg:text-xl md:text-2xl md:leading-8">
            <ul className="list-disc ml-5">
              <li>ಮೇಲ್ಕಂಡ ಎಲ್ಲಾ ಸಮಿತಿಗಳ ಕಾರ್ಯನಿರ್ವಹಣೆಯ ಮೇಲೆ ನಿಗಾ ಇಡುವುದು.</li>
              <li>ಎಲ್ಲ ಸಮಿತಿಗಳು ಕಾರ್ಯಪ್ರವೃತ್ತರಾಗಿರುವಂತೆ ನೋಡಿಕೊಳ್ಳುವುದು.</li>
              <li>ಸಂಘದ ಶಿಸ್ತುಪಾಲನಾ ವರದಿಯನ್ನು ತಯಾರಿಸುವುದು.</li>
              <li>ಸಂಘದ ವಿರುಧ್ದದ ಚಟುವಟಿಕೆಗಳನ್ನು ಗುರುತಿಸಿ ಮಟ್ಟಹಾಕುವುದು.</li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
}

export default Angarachane;
